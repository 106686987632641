import React from "react"

import Layout from "../../components/layout"
import SubServices from "../../components/screens/Services/SubServices"
import { Helmet } from "react-helmet"

const DedicatedSupportPage = () => (
  <Layout pageInfo={{ pageName: "Dedicated Support" }} sitePage="site-page">
    <Helmet defer={false}>
        <title>Dedicated Support</title>
        <meta name="description" content="Dedicated Support" />
    </Helmet>
    <h1 className="name-sub-services">Dedicated Support</h1>
    <div className="wrapper-box-service">
      <SubServices
        left
        image={require("../../images/jpg/sub-services-9.jpg")}
        normal="Your success is our success. Our goals are looking for a long-term relationship with your business. We offer several different retainer options for clients who need ongoing ecommerce support. Whether you have a Magento, Shopify, WordPress, or custom PHP site, our team could properly assist."
      />
      <SubServices
        image={require("../../images/jpg/sub-services-8.jpg")}
        bold="Maintenance and dedicated support packages from OnlineBiz Soft could assure you:"
      >
        <p className="normal-text">
          - The best of the talents with extensive technical knowledge <br/>
          - Efficient communication and task management<br/>
          - Quality customer services <br/>
          - Efficient Cost<br/>
          - 8 hours a day, Monday to Friday - 5 days a week. If requested, we can offer emergency hours during weekend and public holidays.<br/>
          - & More!<br/>
        </p>
      </SubServices>
    </div>
  </Layout>
)

export default DedicatedSupportPage
